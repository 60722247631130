import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import PrivacyPolicy from "../components/legal/PrivacyPolicy"

const ProgramsPage = props => {
  return (
    <Fragment>
      <Helmet>
        <title>Privacy Policy | LaunchPlan</title>
        <meta
          name="description"
          content="Read the privacy policy for using the website and subscription services of LaunchPlan business plan software."
        />
        <meta
          property="og:title"
          content="Privacy Policy | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/privacy-policy" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
        <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <PrivacyPolicy />
    </Fragment>
  )
}

export default ProgramsPage
