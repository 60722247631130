import React, { Component } from "react"
import LegalDocument from "./LegalDocument"
import PublicLayout from "../layouts/PublicLayout"

class PrivacyPolicy extends Component {
  render() {
    return (
      <PublicLayout
        headline={"Privacy Policy"}
        subheading={
          "Your privacy is important to us, please let us know if you have any questions."
        }
        showFreeTrial={false}
      >
        <LegalDocument string={privacyPolicyContent} />
      </PublicLayout>
    )
  }
}

export default PrivacyPolicy

const privacyPolicyContent = `
    This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://launchplan.com (the “Site”).\n
    
    <h2>PERSONAL INFORMATION WE COLLECT</h2>\n

    We utilize a couple of different third parties to allow us to get a better view of how customers and potential customers interact with our online properties. Those services may also automatically collect some of the information below, as well as your IP address, access times, browser type and language, device type, device identifiers, pages visited, and network information. We refer to this information as "Device Information." If you have questions about what information those services collect, we have specified those services below:\n
    
    We collect Device Information using the following technologies:\n

    - Google Analytics (https://policies.google.com/privacy)\n
    - LogRocket (https://logrocket.com/privacy/), LogRocket allows us to record a video of your screen while using our product to help us track down and fix bugs, provide better support to you on your specific issue, and improve our product's user expereience and design.\n
    - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.\n
    - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.\n
    
    When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number. If you choose to refer a friend to our Services, we may also collect your friend’s email address so that we may send them a referral or promotional code to sign up for our Services. We refer to this information as "Account Information."

    Additionally when you make a purchase or attempt to make a purchase through the Site, that information is directed to our third-party payment processor, Stripe. We do not store your financial account information on our systems; however, we have access to, and may retain, subscriber information through our third-party payment processor. We refer to this informatoin as "Payment Information."\n

    - Stripe (Stripe Inc). Stripe is a payment service provided by Stripe Inc. (https://stripe.com/terms/US)
    
    When we talk about “Personal Information” in this Privacy Policy, we are talking about Account Infomation, Device Information and Payment Information.\n
    
    <h2>HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>\n
    
    We use the Payment Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, and providing you with invoices and/or order confirmations).  Additionally, we use this Payment Information to:\n
    Communicate with you;\n
    Screen our orders for potential risk or fraud; and\n
    When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.\n
    We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).\n
    
    <h2>SHARING YOUR PERSONAL INFORMATION</h2>\n
    
    We share your Personal Information with third parties to help us use your Personal Information, as described above. We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.\n
    
    Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.\n

    <h2>INFORMATION STORAGE</h2>

    We share your data and store your data using the following services.

    - DigitalOcean (DigitalOcean Inc.) DigitalOcean is a hosting service provided by DigitalOcean Inc. (https://www.digitalocean.com/legal/privacy/)
    - Google Cloud Platform. Google Cloud Platform is a hosting service provided by Google LLC. (https://cloud.google.com/security/compliance/)
    - MongoDB Atlas. MongoDB Atlas is a MongoDB-as-a-service provided by MongoDB, Inc. (https://www.mongodb.com/legal/privacy-policy)
    - Cloudinary. Cloudinary is a media hosting service provided by Cloudinary Ltd. (https://cloudinary.com/privacy)
    
    <h2>DO NOT TRACK</h2>\n
    Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.\n
    
    <h2>YOUR RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (GDPR)</h2>\n
    If you are a resident of the EEA, you have the following data protection rights:\n
    
    If you wish to access, correct, update, or request deletion of your personal information, you can do so at any time by emailing team@launchplan.com.\n
    In addition, you can object to the processing of your personal information, ask us to restrict the processing of your personal information, or request portability of your personal information. Again, you can exercise these rights by emailing team@launchplan.com.\n
    You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing emails we send you. To opt-out of other forms of marketing, please contact us by emailing team@launchplan.com.\n
    Similarly, if we have collected and process your personal information with your consent, then you canwithdraw your consent at any time.  Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.\n
    You have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority.\n
    We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.\n

    <h2>DATA TRANSFER</h2>\n
    LaunchPlan is a global business. We may transfer personal information to countries other than the country in which the data was originally collected. These countries may not have the same data protection laws as the country in which you initially provided the information. When we transfer your personal information to other countries, we will protect that information as described in this Privacy Policy.\n
    
    <h2>DATA RETENTION</h2>\n
    We retain personal information we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with a service you have requested or to comply with applicable legal, tax, or accounting requirements).\n

    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.\n
    
    <h2>CHANGES</h2>\n
    We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.\n
    
    <h2>CONTACT US</h2>\n
    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at team@launchplan.com or by mail using the details provided below:\n
    
    33 Park View Ave Apt 2401, Jersey City, NJ, 07302, United States\n
    `
